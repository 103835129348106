<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h4>{{this.$route.name}}</h4>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "orderPrint",
}
</script>

<style>

</style>